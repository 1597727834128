import React from "react";
import custombrand from "../../../assets/images/custom-brand.webp";
import customdesign from "../../../assets/images/custom-design.webp";
import distribute from "../../../assets/images/distribute.webp";
import highengegement from "../../../assets/images/high-engagement.jpg";
import "./Services.css";
import styled, { keyframes } from "styled-components";
import { fadeInUp } from "react-animations";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useInView } from "react-intersection-observer";

const fadeInUpAnimation = keyframes`${fadeInUp}`;

const Animated2sDiv = styled.div`
  animation: 2s ease ${fadeInUpAnimation};
`;
const Animated1sDiv = styled.div`
  animation: 2s ease ${fadeInUpAnimation};
`;
const Animated3sDiv = styled.div`
  animation: 2.5s ease ${fadeInUpAnimation};
`;
const Animated5sDiv = styled.div`
  animation: 3s ease ${fadeInUpAnimation};
`;
const Animated7sDiv = styled.div`
  animation: 3.5s ease ${fadeInUpAnimation};
`;

const Services: React.FC = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  return (
    <div ref={ref} className="service py-5">
      <Container className="py-5">
        {inView && (
          <Animated2sDiv className="section-title mb-5">
            <div className="sub-style">
              <h4 className="sub-title px-3 mb-0">What We Do</h4>
            </div>
            <h1 className="display-3 mb-4">
              Water that nourishes, labels that advertise - a winning combo!
            </h1>
          </Animated2sDiv>
        )}
        {inView && (
          <Row className="g-4 justify-content-center">
            <Col md={6} lg={4} xl={3}>
              <Animated1sDiv>
                <Card className="service-item rounded">
                  <div className="service-img rounded-top">
                    <Card.Img
                      variant="top"
                      src={custombrand}
                      className="img-fluid rounded-top w-100"
                    />
                  </div>
                  <Card.Body className="service-content rounded-botton bg-light p-4">
                    <Card.Title>Custom Bottle Branding</Card.Title>
                    <Card.Text>
                      Impress your customers by showcasing your brand logo
                      prominently on our mineral water bottles. Elevate your
                      brand image effortlessly and leave a lasting impression on
                      your clientele
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Animated1sDiv>
            </Col>
            <Col md={6} lg={4} xl={3}>
              <Animated7sDiv>
                <Card className="service-item rounded">
                  <div className="service-img rounded-top">
                    <Card.Img
                      variant="top"
                      src={highengegement}
                      className="img-fluid rounded-top w-100"
                    />
                  </div>
                  <Card.Body className="service-content rounded-botton bg-light p-4">
                    <Card.Title>Festive Customization</Card.Title>
                    <Card.Text>
                      Customize labels for special occasions and festive
                      seasons. Whether it's Diwali, Christmas, or New Year,
                      align your brand with the spirit of celebration and
                      captivate your audience with themed labels.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Animated7sDiv>
            </Col>
            <Col md={6} lg={4} xl={3}>
              <Animated7sDiv>
                <Card className="service-item rounded">
                  <div className="service-img rounded-top">
                    <Card.Img
                      variant="top"
                      src={highengegement}
                      className="img-fluid rounded-top w-100"
                    />
                  </div>
                  <Card.Body className="service-content rounded-botton bg-light p-4">
                    <Card.Title>
                      Offers to Drive Customer Engagement and Loyalty:
                    </Card.Title>
                    <Card.Text>
                      Enhance customer loyalty and drive repeat business by
                      offering exclusive deals and promotions directly on the
                      bottle labels. Encourage customers to return and engage
                      with your brand by enticing them with special discounts
                      and offers.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Animated7sDiv>
            </Col>
            <Col md={6} lg={4} xl={3}>
              <Animated7sDiv>
                <Card className="service-item rounded">
                  <div className="service-img rounded-top">
                    <Card.Img
                      variant="top"
                      src={highengegement}
                      className="img-fluid rounded-top w-100"
                    />
                  </div>
                  <Card.Body className="service-content rounded-botton bg-light p-4">
                    <Card.Title>
                      QR Code Integration for Increased Website Traffic
                    </Card.Title>
                    <Card.Text>
                      Generate and attach QR codes to your water bottles to
                      boost website traffic and visibility. Drive customer
                      engagement and recognition by providing easy access to
                      your online platforms, promotions, and exclusive offers.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Animated7sDiv>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default Services;
