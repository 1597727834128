import CarouselComponent from "../../../lib/ui/Carousel";
import car1 from "../../../assets/images/car-1.webp";
import car2 from "../../../assets/images/car-2.webp";
import Services from "./Services";
import FeatureComponent from "../../../lib/ui/FeatureComponent";
import AboutUsHome from "./AboutUsHome";

const Home = () => {
  const features = [
    {
      title: "Reach with Purpose",
      description:
        "Align your brand with a vital resource. Water is universal, and so is your visibility. Advertise on our bottles, and your message travels into homes, offices, and events, making meaningful impressions in daily moments.",
      iconName: "fa-sort-amount-up",
    },
    {
      title: "Sustainable Branding",
      description:
        "Show your commitment to sustainability. Our eco-friendly bottles are a testament to environmentally conscious choices, reflecting positively on your brand values and appealing to a growing segment of eco-aware consumers.",
      iconName: "fa-pager",
    },

    {
      title: "Community Connection",
      description:
        "Be part of a story of change. Your advertisement supports our mission to provide clean, safe water across India, including underserved areas. This partnership showcases your brand as an active participant in social responsibility.",
      iconName: "fa-users",
    },
    {
      title: "Customized Campaigns",
      description:
        "Tailor your message for maximum impact. We work closely with you to design campaigns that resonate with your target audience, ensuring your brand's message is both seen and felt.",
      iconName: "fa-paint-brush",
    },

    {
      title: "High Engagement",
      description:
        "Benefit from high retention and engagement. Water bottles are personal and portable, accompanying your audience throughout their day, offering repeated exposure in a non-intrusive manner.",
      iconName: "fa-user-plus",
    },
    {
      title: "Cost-Effective Advertising",
      description:
        "Achieve more with less. Water bottle advertising offers a unique and cost-effective avenue to reach potential customers, reducing the need for expensive media buys while maximizing reach and engagement.",
      iconName: "fa-hand-holding-usd",
    },

    {
      title: "Positive Association",
      description:
        "Achieve more with less. Water bottle advertising offers a unique and cost-effective avenue to reach potential customers, reducing the need for expensive media buys while maximizing reach and engagement.",
      iconName: "fa-thumbs-up",
    },
    {
      title: "Analytics and Insights",
      description:
        "Measure impact with precision. We provide detailed campaign analytics, offering insights into reach and engagement, helping you understand the effectiveness of your advertisement spend.",
      iconName: "fa-cogs",
    },
  ];
  const feature = {
    heading: "Why Choose Us",
    subHeading: "Why Water Bottle Advertising?",
    items: features
  }
  const car = [
    {
      carouselCaption: "Advertising Solution",
      carouselDescription: "Sip on innovation and promote your business!",
      imageUrl: car1,
    },
    {
      carouselCaption: "Advertising Solution",
      carouselDescription: "Quench your thirst with a splash of creativity!",
      imageUrl: car2,
    },
  ];
  return (
    <div>
      <CarouselComponent items={car}></CarouselComponent>
      <Services></Services>
      <AboutUsHome></AboutUsHome>
      <FeatureComponent features={feature}></FeatureComponent>
    </div>
  );
};

export default Home;
